<template>
  <auth-layout v-if="access_token == null">
    <div class="auth-login-layout">
      <!-- Login Form -->
      <login-form />

      <div class="register-link col-12 font-size-sm">
        {{ $t("auth.login.head") }}
        <router-link to="/register">{{ $t("auth.login.register") }}</router-link>
      </div>
      <div class="forgot-password font-size-sm">
        <router-link to="/forgot-password">{{ $t("auth.forgot-pass.head") }}</router-link>
      </div>

    </div>
  </auth-layout>
</template>

<script>
import {mapGetters} from "vuex";
import AuthLayout from "./partials/AuthLayout";
import LoginForm from "@/views/Auth/partials/LoginForm";
import {useMeta} from "vue-meta";
import {useI18n} from "vue-i18n";

export default {
  setup() {
    const {t} = useI18n() // use as global scope
    useMeta({
      title: t("auth.login.page_title"),
    })
  },
  components: {
    LoginForm,
    AuthLayout
  },
  name: "LoginShow",
  computed: {
    ...mapGetters(["access_token"]),
  },
};
</script>

<style lang="scss">
</style>
